


























































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import { namespace } from 'vuex-class'
import cloneDeep from 'lodash/cloneDeep'

import FinishExamModal from '@/partials/modals/TakeExam/finish.vue'
import LeftWindowMessage from '@/partials/modals/TakeExam/LeftWindow.vue'

import TakeExamWindowSizeMixin from '@/mixins/takeExamWindowSize'

const takeExamModule = namespace('takeExam')

@Component({
  components: {
    FinishExamModal,
    LeftWindowMessage
  }
})
export default class TakeExamQuestionShow extends Mixins(TakeExamWindowSizeMixin) {
  @takeExamModule.Getter getQuestion!: any
  @takeExamModule.Getter getExam!: any
  @takeExamModule.Getter getExamStatus!: string
  @takeExamModule.Getter getLeftWindowCounter!: number
  @takeExamModule.Getter getExamCountdown!: number
  @takeExamModule.Action fetchNextQuestion!: Function
  @takeExamModule.Action fetchPrevQuestion!: Function
  @takeExamModule.Action incrementLeftWindow!: Function
  @takeExamModule.Action decrementExamCountdown!: Function
  @takeExamModule.Action setLeftWindow!: Function
  @takeExamModule.Action setExamScore!: Function
  @takeExamModule.Mutation SET_STATUS!: (status: string) => void
  finishExamModalIsOpened = false
  questionTimeIsUp = false

  examQuestion: any = {}

  questionCountdown = 60
  questionCountdownEvent: any = {}

  get examCountdown () {
    return this.getExamCountdown
  }

  examCountdownEvent: any = {}

  hasFocus = true
  done = false

  created () {
    this.refreshQuestion()
  }

  @Watch('getExamStatus', { immediate: true })
  onStatusChange (newValue: string) {
    if (newValue) {
      this.$router.push({ name: 'TakeExamResult' })
    }
  }

  get examProperties () {
    return this.getExam
  }

  focus = () => { this.hasFocus = true }
  blur () {
    this.hasFocus = false

    if (!this.examProperties.switchTabs) {
      this.leftWindow()
    }
  }

  beforeunload = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  @Watch('isCorrectDisplay', { immediate: true })
  onCorrectDisplayChange (value: boolean) {
    if (!value) {
      this.leftWindow()
    }
  }

  leftWindow () {
    if (!this.examProperties.switchTabs) {
      if (process.env.NODE_ENV === 'development') {
        console.log('saiu')
      } else {
        this.incrementLeftWindow()
      }
    }
  }

  get leftWindowCounter () { return this.getLeftWindowCounter }
  leftWindowModalIsOpened = false

  @Watch('getLeftWindowCounter')
  onLeftWindowCounterChange (value: number) {
    if (value > 0) {
      this.leftWindowModalIsOpened = true
    }
    if (value >= 2) {
      this.eliminateCandidate()
    }
  }

  eliminateCandidate () {
    axios.get(`user/${this.$route.params.examScheduleServiceUserId}/exam/${this.getExam.id}/eliminate`)
      .then((response) => {
        this.setExamScore(response.data.data[0].score)
      })
  }

  @Watch('getQuestion')
  refreshQuestion () {
    this.removeEventListeners()
    this.examQuestion = cloneDeep(this.getQuestion)

    if (this.examProperties.useQuestionTimer) {
      this.questionCountdown = this.examQuestion.time
      this.questionCountdownEvent = setInterval(() => {
        this.questionCountdown -= 1
      }, 1000)
    }

    if (this.examProperties.useExamTimer) {
      this.examCountdownEvent = setInterval(() => {
        this.decrementExamCountdown()
      }, 1000)
    }

    window.addEventListener('blur', this.blur)
    window.addEventListener('focus', this.focus)
    window.addEventListener('beforeunload', this.beforeunload)

    this.done = true
  }

  @Watch('questionCountdown')
  onCountdownChange () {
    if (this.questionCountdown <= 0) {
      clearInterval(this.questionCountdownEvent)
      this.questionTimeIsUp = true
    }
  }

  @Watch('questionCountdown')
  finishExamOnCountdown () {
    if (this.examCountdown <= 0) {
      this.finishExam()
    }
  }

  finishExam () {
    axios.get(`user/${this.$route.params.examScheduleServiceUserId}/exam/${this.getExam.id}/finish`)
      .then((response) => {
        this.setExamScore(response.data.data[0].score)
        this.redirectToResults()
      })
      .catch((err) => {
        const status = err.response.status

        if (status === 403) {
          this.SET_STATUS(err.response.data?.data)
        }
      })
  }

  beforeDestroy () {
    this.removeEventListeners()
  }

  goToPrevQuestion () {
    this.done = false
    this.fetchPrevQuestion(this.$route.params.examScheduleServiceUserId).then(this.navigateToQuestion)
  }

  goToNextQuestion () {
    this.done = false
    if (this.examQuestion.nextQuestionId !== null) {
      this.fetchNextQuestion(this.$route.params.examScheduleServiceUserId).then(this.navigateToQuestion)
    } else {
      this.finishExam()
    }
  }

  navigateToQuestion () {
    const question = cloneDeep(this.getQuestion)
    const { examScheduleServiceUserId } = this.$route.params

    this.$router.push({
      name: 'TakeExamQuestion',
      params: { examScheduleServiceUserId, examQuestionId: question.id }
    })
  }

  removeEventListeners () {
    this.resetForm()
    window.removeEventListener('blur', this.blur)
    window.removeEventListener('focus', this.focus)
    window.removeEventListener('beforeunload', this.beforeunload)

    this.questionTimeIsUp = false

    for (let i = 1; i < 99999; i++) { window.clearInterval(i) }

    this.done = true
  }

  form = {
    optionId: '',
    answer: ''
  }

  submit () {
    this.done = false
    let formSubmit: Record<string, string>

    if (this.examQuestion.question.type === 'radio') {
      formSubmit = { ...snakeCaseKeys(this.form) }
    } else {
      formSubmit = snakeCaseKeys({ ...this.form, optionId: this.examQuestion.question.options[0].id })
    }
    const { examScheduleServiceUserId } = this.$route.params

    axios.post(`user/${examScheduleServiceUserId}/exam/${this.getExam.id}/question/${this.examQuestion.id}`, formSubmit)
      .then(() => {
        this.goToNextQuestion()
      })
      .catch((err) => {
        const status = err.response.status

        if (status === 403) {
          this.SET_STATUS(err.response.data?.data)
        }
      })
      .finally(() => {
        this.done = true
        this.resetForm()
      })
  }

  resetForm () {
    this.form = {
      optionId: '',
      answer: ''
    }
  }

  redirectToResults () {
    this.finishExamModalIsOpened = false
    this.setLeftWindow(0)
    this.removeEventListeners()
    this.$router.push({ name: 'TakeExamResult', params: { examScheduleServiceUserId: this.$route.params.examScheduleServiceUserId } })
  }

  get timerQuestionClass () {
    const isDangerousTime = this.examQuestion.time / 6 > this.questionCountdown
    const isOddTime = this.questionCountdown % 2 && this.examQuestion.time / 10
    return {
      'font-bold': isOddTime && isDangerousTime,
      'text-red-600': isDangerousTime
    }
  }

  get timerExamClass () {
    const isDangerousTime = this.examProperties.timeMax / 6 > this.examCountdown
    const isOddTime = this.examCountdown % 2 && this.examProperties.timeMax / 10
    return {
      'font-bold': isOddTime && isDangerousTime,
      'text-red-600': isDangerousTime
    }
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
