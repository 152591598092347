

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class LeftWindowTakeExam extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ required: true }) kind!: LeftWindowTakeExamKind

  message = {
    title: '',
    content: ''
  }

  @Watch('kind', { immediate: true })
  onKindChange (kindValue: LeftWindowTakeExamKind) {
    if (kindValue === 'warning') {
      this.message = {
        title: 'Não saia da tela de prova novamente',
        content: 'Você será eliminado na próxima tentativa'
      }
    } else if (kindValue === 'eliminated') {
      this.message = {
        title: 'Você foi eliminado da prova',
        content: 'Motivo: multiplas saidas da tela de prova'
      }
    }
  }

  submit () {
    if (this.kind === 'eliminated') {
      this.$emit('submitted')
    } else {
      this.$emit('close')
    }
  }
}
