





























import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { namespace } from 'vuex-class'

const takeExamModule = namespace('takeExam')

@Component
export default class FinishTakeExam extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ required: true }) examId!: string
  @takeExamModule.Action setExamScore!: Function
  @takeExamModule.Mutation SET_STATUS!: (status: string) => void

  closeModal () {
    this.$emit('close')
  }

  async submit () {
    await axios.get(`user/${this.$route.params.examScheduleServiceUserId}/exam/${this.examId}/finish`)
      .then(async response => {
        await this.setExamScore(response.data.data[0].score)
        this.$emit('submitted', response.data.data)
      })
      .catch((err) => {
        const status = err.response.status

        if (status === 403) {
          this.SET_STATUS(err.response.data?.data)
        }
      })
  }
}
