import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TakeExamWindowSizeMixin extends Vue {
  heightRatio = window.outerHeight / screen.availHeight
  widthRatio = window.innerWidth / screen.availWidth

  onResize () {
    this.heightRatio = window.outerHeight / screen.availHeight
    this.widthRatio = window.innerWidth / screen.availWidth
  }

  created () {
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  get isCorrectDisplay () {
    return this.heightRatio > 0.90 && this.widthRatio > 0.90
  }
}
